<template>
  <a-modal
    :visible="visible"
    class="keyword-modal"
    :title="
      mode === 'add' ? 'Add Keyword Notification' : 'Edit Keyword Notification'
    "
    :ok-text="mode === 'add' ? 'Add' : 'Save Changes'"
    @ok="handleOk"
    @cancel="close"
  >
    <span>
      <div class="modal-field-title">Type</div>
      <div class="select-keyword-mode">
        <a-select
          v-model:value="inputValue.inputType"
          :getPopupContainer="(trigger) => trigger.parentNode"
          class="keyword-mode-selector"
          style="width: 100%"
        >
          <a-select-option value="username">Username</a-select-option>
          <a-select-option value="keyword"> Word </a-select-option>
          <a-select-option value="hashtag"> Hashtag </a-select-option>
        </a-select>
      </div>
    </span>

    <div class="modal-field-title">Keyword</div>
    <a-input v-model:value="inputValue.inputKeyword" />

    <div class="modal-field-title">Sentiment</div>
    <div class="select-keyword-mode">
      <a-select
        v-model:value="inputValue.inputSentiment"
        :getPopupContainer="(trigger) => trigger.parentNode"
        class="keyword-mode-selector"
        style="width: 100%"
      >
        <a-select-option value="positive">
          <span>
            <FeatherIcon
              type="smile"
              size="24"
              color="#20c997"
            />
            <span class="text-sentiment">Positive</span>
          </span>
        </a-select-option>
        <a-select-option value="neutral">
          <span>
            <FeatherIcon
              type="meh"
              size="24"
              color="#fa8b0c"
            />
            <span class="text-sentiment">Neutral</span>
          </span>
        </a-select-option>
        <a-select-option value="negative">
          <span>
            <FeatherIcon
              type="frown"
              size="24"
              color="#ff4d4f"
            />
            <span class="text-sentiment">Negative</span>
          </span>
        </a-select-option>
      </a-select>
    </div>
  </a-modal>
</template>

<script>
import { toRefs, reactive, watch } from 'vue';
import api from '@/services/api';
import { notification } from 'ant-design-vue';
// import { DownOutlined } from '@ant-design/icons-vue';

export default {
  name: 'AddKeywordModal',
  props: {
    mode: {
      type: String,
      default() {
        return 'add';
      },
    },
    keywordObj: {
      type: Object,
      default() {
        return {};
      },
    },
    visible: {
      type: Boolean,
    },
  },
  components: {
    // DownOutlined,
  },
  emits: ['close', 'reloadKeywordList'],
  setup(props, { emit }) {
    const { mode, keywordObj, visible } = toRefs(props);
    const inputValue = reactive({
      inputType: 'keyword',
      inputKeyword: '',
      inputSentiment: 'negative',
    });

    const backupData = reactive({
      inputType: 'keyword',
      inputKeyword: '',
      inputSentiment: 'negative',
    });

    watch(visible, () => {
      if (mode.value === 'edit') {
        inputValue.inputKeyword = keywordObj.value.id;
        inputValue.inputType = keywordObj.value.type;
        inputValue.inputSentiment = convertValueSentiment(
          keywordObj.value.value
        );

        // backup data
        backupData.inputKeyword = keywordObj.value.id;
        backupData.inputType = keywordObj.value.type;
        backupData.inputSentiment = convertValueSentiment(
          keywordObj.value.value
        );
      } else if (mode.value === 'add') {
        inputValue.inputType = 'keyword';
        inputValue.inputKeyword = '';
        inputValue.inputSentiment = 'negative';
      }
    });

    const close = () => {
      clearInputValue();
      emit('close');
    };

    const clearInputValue = () => {
      inputValue.inputType = 'keyword';
      inputValue.inputKeyword = '';
      inputValue.inputSentiment = 'negative';
    };

    const handleOk = async () => {
      if (mode.value === 'add') {
        addKeyword();
      } else if (mode.value === 'edit') {
        const keywordId = convertKeywordForRemove(); // for delete old kw > backupData.inputKeyword
        const result = await api.removeAlertKeyword(keywordId).catch(() => {
          notification.error({
            message: 'Error',
            description: 'Keyword edit failed.',
          });
        });

        if (result) {
          notification.success({
            message: 'Success',
            description: 'Keyword updated.',
          });
          addKeyword();
        }
      }
    };

    const convertKeywordForRemove = () => {
      const { inputType, inputKeyword } = backupData;
      if (inputType === 'hashtag') {
        if (inputKeyword.charAt(0) !== '#') {
          return `#${inputKeyword}`;
        }

        return inputKeyword;
      } else if (inputType === 'username') {
        if (inputKeyword.charAt(0) === '@') {
          return `username:${inputKeyword.slice(1)}`;
        }
        return `username:${inputKeyword}`;
      } else if (inputType === 'keyword') {
        if (inputKeyword.charAt(0) === '@') {
          return inputKeyword.slice(1);
        }
        // if inputType = word
        return inputKeyword;
      }

      // old
      // if (keyword.charAt(0) === '@') {
      //   return `username:${keyword.slice(1)}`;
      // }
      return inputKeyword;
    };

    const addKeyword = async () => {
      const ans = prepareKeywordForAdd(inputValue);
      const result = await api
        .addAlertKeyword(ans.id, ans.value)
        .catch((errorResult) => {
          if (errorResult.response && errorResult.response.status === 400) {
            notification.error({
              message: 'Error',
              description: 'Keyword is already exist.',
            });
          } else if (
            errorResult.response &&
            errorResult.response.status === 406 &&
            errorResult.response.data &&
            errorResult.response.data.message
          ) {
            const messageError =
              'Keyword add failed. ' + errorResult.response.data.message;
            notification.error({
              message: 'Error',
              description: messageError,
            });
          } else {
            notification.error({
              message: 'Error',
              description: 'Keyword add failed.',
            });
          }
        });

      if (result && result.code === 200) {
        clearInputValue();
        emit('reloadKeywordList');
      } else if (result && result.code === 400) {
        notification.error({
          message: 'Error',
          description: 'Keyword is already exist.',
        });
      }
    };

    const prepareKeywordForAdd = (obj) => {
      const { inputType, inputKeyword, inputSentiment } = obj;
      if (inputType === 'hashtag') {
        if (inputKeyword.charAt(0) !== '#') {
          if (inputKeyword.charAt(0) === '@') {
            return {
              id: `#${inputKeyword.slice(1)}`,
              value: convertSentiment(inputSentiment),
            };
          } else if (inputKeyword.indexOf('username:') === 0) {
            return {
              id: `#${inputKeyword.slice(9)}`,
              value: convertSentiment(inputSentiment),
            };
          }

          return {
            id: `#${inputKeyword}`,
            value: convertSentiment(inputSentiment),
          };
        }

        return {
          id: inputKeyword,
          value: convertSentiment(inputSentiment),
        };
      } else if (inputType === 'username') {
        if (inputKeyword.charAt(0) === '@') {
          return {
            id: `username:${inputKeyword.slice(1)}`,
            value: convertSentiment(inputSentiment),
          };
        } else if (inputKeyword.indexOf('username:') === 0) {
          return {
            id: `username:${inputKeyword.slice(9)}`,
            value: convertSentiment(inputSentiment),
          };
        } else if (inputKeyword.charAt(0) === '#') {
          return {
            id: `username:${inputKeyword.slice(1)}`,
            value: convertSentiment(inputSentiment),
          };
        }

        return {
          id: `username:${inputKeyword}`,
          value: convertSentiment(inputSentiment),
        };
      } else if (inputType === 'keyword') {
        if (inputKeyword.charAt(0) === '@') {
          return {
            id: inputKeyword.slice(1),
            value: convertSentiment(inputSentiment),
          };
        } else if (inputKeyword.indexOf('username:') === 0) {
          return {
            id: inputKeyword.slice(9),
            value: convertSentiment(inputSentiment),
          };
        } else if (inputKeyword.charAt(0) === '#') {
          return {
            id: inputKeyword.slice(1),
            value: convertSentiment(inputSentiment),
          };
        }

        return {
          id: inputKeyword,
          value: convertSentiment(inputSentiment),
        };
      }

      return {
        id: inputKeyword,
        value: convertSentiment(inputSentiment),
      };
    };

    const convertSentiment = (sentiment) => {
      if (sentiment === 'positive') {
        return 1.5;
      }

      if (sentiment === 'neutral') {
        return 0;
      }

      return -1.5;
    };

    const convertValueSentiment = (sentiment) => {
      if (sentiment > 1) {
        return 'positive';
      }

      if (sentiment < -1) {
        return 'negative';
      }

      return 'neutral';
    };

    return {
      inputValue,
      close,
      handleOk,
    };
  },
};
</script>

<style lang="scss">
.keyword-modal {
  .modal-field-title {
    line-height: 24px;
    font-weight: 500;
    margin-top: 20px;

    &.no-margin {
      margin: 0;
    }
  }
  .keyword-dropdown {
    border: 1px solid #e3e6ef;
    padding: 12px;
    border-radius: 4px;
    color: #5a5f7d;
    display: inline-block;
    white-space: nowrap;
    width: 100%;
  }

  .ant-input {
    border: 1px solid #e3e6ef;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 6px 0px;
    height: 48px;
  }

  .select-keyword-mode {
    .keyword-mode-selector .ant-select-selector {
      align-items: flex-start;
      padding: 12px 11px;
      // position: static;
      height: 48px;
      border: 1px solid #e3e6ef;
      border-radius: 4px;
      margin: 6px 0px;

      .ant-select-selection-item {
        line-height: 24px !important;
      }
    }
  }

  .text-sentiment {
    margin-left: 10px;
    display: table-caption;
  }
}
</style>
