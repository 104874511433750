<template>
  <div class="table-top-keyword table-bordered table-responsive">
    <div class="title">Top 10 Keyword</div>
    <div
      v-if="loading"
      class="load-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <div v-if="!loading && top10Keyword && top10Keyword.length > 0">
      <a-table
        rowKey="no"
        :columns="columnConfig"
        :dataSource="top10Keyword"
        :pagination="false"
      />
    </div>

    <div v-if="!loading && top10Keyword && top10Keyword.length <= 0">
      <NoDataAspect :title="'No Top 10 Keyword'"> </NoDataAspect>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from 'vue';
import api from '@/services/api';
import NoDataAspect from '@/components//Error/NoDataAspect.vue';

export default {
  components: {
    NoDataAspect,
  },
  setup() {
    let loading = ref(true);
    let keywordList = ref([]);

    const convertKeywordList = computed(() => {
      const data = keywordList.value;
      const list = [];
      if (data && data.length > 0) {
        data.forEach((value, index) => {
          list.push({
            no: index + 1,
            keyword: value.keyword,
            count: value.count,
          });
        });
      }
      return list;
    });
    const top10Keyword = computed(() => {
      if (convertKeywordList.value && convertKeywordList.value.length) {
        return convertKeywordList.value.slice(0, 10);
      }
      return [];
    });

    const columnConfig = [
      {
        title: '#',
        dataIndex: 'no',
        key: 'no',
      },
      {
        title: 'Keyword',
        dataIndex: 'keyword',
        key: 'keyword',
      },
      {
        title: 'Messages',
        dataIndex: 'count',
        key: 'count',
      },
    ];

    const getTopAlertKeyword = async () => {
      loading.value = true;
      const result = await api.getTopKeywordStat().catch((e) => {
        console.log(e);
      });

      if (
        result &&
        result.message &&
        result.message.keywordStatList &&
        result.message.keywordStatList.length > 0
      ) {
        keywordList.value = result.message.keywordStatList;
        for (let item in keywordList.value) {
          if (keywordList.value[item].keyword.charAt(0) === '#') {
            keywordList.value[
              item
            ].keyword = `${keywordList.value[item].keyword}`;
          } else if (
            keywordList.value[item].keyword.indexOf('username:') === 0
          ) {
            keywordList.value[item].keyword = `@${keywordList.value[
              item
            ].keyword.slice(9)}`;
          } else {
            keywordList.value[
              item
            ].keyword = `${keywordList.value[item].keyword}`;
          }
        }
      }
      loading.value = false;
    };

    onMounted(() => {
      getTopAlertKeyword();
    });

    return {
      loading,
      top10Keyword,
      columnConfig,
    };
  },
};
</script>

<style lang="scss">
.table-top-keyword {
  .ant-table-thead > tr:first-child > th:first-child {
    border-radius: 0px !important;
    border-left: 0px !important;
  }
  .ant-table-thead > tr:last-child > th:last-child {
    border-radius: 0px !important;
    border-right: 0px !important;
  }

  .title {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #272b41;
  }
}
</style>
