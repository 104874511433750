<template>
  <a-card class="border">
    <a-row
      justify="space-between"
      align="middle"
    >
      <a-col><span class="title color-dark">Data Usage</span></a-col>
      <!-- <a-col>
        <a href="#" class="align-center-v history color-primary"
          >History <FeatherIcon type="arrow-right" class="pl-2"></FeatherIcon></a
      ></a-col> -->
    </a-row>
    <a-row
      justify="space-between"
      class="mt-20"
    >
      <a-col
        ><span class="usage-count color-primary">{{ used }}</span></a-col
      >
      <a-col
        ><span class="percent color-gray">{{ percent }}%</span></a-col
      >
    </a-row>

    <div>
      <a-progress
        :percent="percent"
        :show-info="false"
        color="primary"
      />
    </div>

    <div>
      <span class="package">Package: {{ messages }} Messages/Month</span>
    </div>
  </a-card>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import helper from '@/services/helper';
export default {
  props: {
    usage: {
      type: Object,
      default: () => {
        return {
          used: 1234567,
          total: 10000000,
        };
      },
    },
  },
  setup() {
    const store = useStore();

    const used = computed(() => {
      return helper.numeral(store.state.account.totalUsage);
    });
    const messages = computed(() => {
      const { quota } = store.state.account.summary;
      if (quota && quota > 0) {
        return helper.numeral(quota);
      }
      return 'unlimited';
    });
    const percent = computed(() => {
      if (store.state.account.summary.quota) {
        return Math.ceil(
          (store.state.account.totalUsage / store.state.account.summary.quota) *
            100
        );
      } else {
        return 0;
      }
    });

    return {
      used,
      messages,
      percent,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.pl-2 {
  padding-left: 2px;
}

.history {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.usage-count {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.percent {
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.package {
  font-family: DM Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #9299b8;
}

.border {
  border: 1px solid #f1f2f6;
}
</style>
